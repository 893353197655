<template>
    <v-row class="mx-0">
        <v-col class="pa-0 paragraph" cols="12" :class="new_val.selected ? '':'cell4--text'">
            <v-row align="center" no-gutters>
                <v-col cols="auto">
                    <div style="width: 48px"></div>
                </v-col>
                <v-col cols="4"></v-col>
                <v-col :align="new_val.selected ? 'center':''">{{ $tc('global.product.uni_price', 1) }}</v-col>
                <v-col align="center" v-show="new_val.selected">{{ $tc('global.product.quantity', 1) }}</v-col>
                <v-col align="center" v-show="new_val.selected">{{ $tc('global.product.subtotal', 1) }}</v-col>
                <v-col align="right" v-show="new_val.selected">{{ $tc('global.product.tax', 1) }}</v-col>
            </v-row>

            <v-row align="center" no-gutters>
                <v-col cols="auto">
                    <v-checkbox dense v-model="new_val.selected" @change="new_val.selected ? new_val.count = 1 : new_val.count = 0" class="mr-4"/>
                </v-col>

                <v-col cols="4">
                    <div class="title-3 font-weight-bold">{{ new_val.display_name }}</div>
                    <div>{{ $tc('global.name.category', 1) }}: {{ new_val.category ? new_val.category.display_name : '' }}
                    </div>
                </v-col>

                <v-col :align="new_val.selected ? 'center':''">
                    <div class="title-2" v-html="$options.filters.formatPrice(new_val.price, '€')"></div>
                    <div v-show="!new_val.selected">{{ new_val.tax ? new_val.tax.display_name :'' }}
                    </div>
                </v-col>

                <v-slide-x-transition>
                    <v-col v-show="new_val.selected" align="center">
                        <v-row no-gutters align="center" justify="center">
                            <v-btn icon small v-if="!new_val.uniq" @click="new_val.count > 1 ? new_val.count-- : ''">
                                <v-icon small>mdi-minus</v-icon>
                            </v-btn>
                            <div class="sub-title-1 text-center mx-1" style="width: 20px">{{ new_val.count }}</div>
                            <v-btn icon small v-if="!new_val.uniq" @click="new_val.count++">
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </v-row>
                        <div>x{{ product.count }}</div>
                    </v-col>
                </v-slide-x-transition>
                <v-slide-x-transition>
                    <v-col v-show="new_val.selected" align="center">
                        <div class="title-2 primary--text" v-html="$options.filters.formatPrice(new_val.price * new_val.count, '€')"></div>
                    </v-col>
                </v-slide-x-transition>
                 <v-slide-x-transition>
                    <v-col v-show="new_val.selected" align="right" v-if="new_val.tax.length > 0">
                        <div v-for="t in new_val.tax" :key="t.id">{{ t.display_name }}</div>
                    </v-col>
                    <v-col v-show="new_val.selected" align="right" v-else>
                        <div>{{ $t('Cart.none') }}</div>
                    </v-col>
                </v-slide-x-transition>
            </v-row>
        </v-col>
        <v-col cols="12">
            <v-divider class="my-4" v-if="product.extensions && !last"/>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "Extension",
    props: ['product', 'value', 'last'],
    computed: {
        new_val: {
            get(){
                return this.value
            },
            set(val){
                this.$emit('input', val)
            }
        }
    }
}
</script>